import React from "react";
import Default from "../user/Financial";

function ProtectedPage() {
  return (
    <React.Fragment>
      {/* <Alert mb={4} severity="info">
        Essa página só é visivel para usuários logados.
      </Alert> */}

      <Default />
    </React.Fragment>
  );
}

export default ProtectedPage;

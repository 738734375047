import React from "react";

import { ReactComponent as Logo } from "../vendor/logo.svg";
import { ReactComponent as LightLogo } from "../vendor/lightLogo.svg";

const BrandLogo = ({ isLight = false, className }) => {
  return isLight ? (
    <Logo className={className} />
  ) : (
    <Logo className={className} />
  );
};

export default BrandLogo;

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import styled, { withTheme } from "styled-components/macro";
import {
  Button,
  Grid,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Divider as MuiDivider,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Input,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert } from "@material-ui/lab";
import axios from "../../../utils/axios";
import { LOGIN_URL } from "../../../constants/urls";
import { useState } from "react";

const TextField = styled(MuiTextField)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CreateButton = styled(Button)`
  padding: 10px 16px;
`;

const createUser = async (
  { name, email, password, companies, permissions },
  jwt
) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        LOGIN_URL + "/users/create",
        {
          name,
          email,
          password,
          companies,
          permissions: permissions.join(","),
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          return resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error.message);
      });
  });
};

const editUser = async (user, jwt, email) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        LOGIN_URL + "/users/edit",
        {
          email,
          user: { ...user, permissions: user.permissions.join(",") },
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          return resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error.message);
      });
  });
};

const ACTIONS = {
  create: {
    title: "Criar Novo Usuário",
    password: "Senha",
    button: "Criar Usuário",
    action: createUser,
    success: "Usuário criado com sucesso",
  },
  edit: {
    title: "Editar Usuário",
    password: "Senha (deixe vazio para não alterar)",
    button: "Editar Usuário",
    action: editUser,
    success: "Usuário alterado com sucesso",
  },
};

const PERMISSIONS = [
  {
    id: "dashboard",
    value: "Dashboard",
  },
  {
    id: "financial",
    value: "Financeiro",
  },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function UserForm({ location: { state } }) {
  const {
    user: { jwt },
  } = useSelector((state) => state.authReducer);

  const [companies, setCompanies] = useState([]);
  const [loading, isLoading] = useState(true);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [userCompanies, setUserCompanies] = useState(
    state?.user?.companies || []
  );
  const [userPermissions, setUserPermissions] = useState(
    state?.user?.permissions?.split(",") || []
  );
  console.log(userPermissions);

  const loadList = () => {
    isLoading(true);
    setCompanies([]);
    setSuccess(false);
    setError(false);
    axios
      .get(LOGIN_URL + "/companies", {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        isLoading(false);
        if (response.status === 200) {
          return setCompanies(response.data);
        }
        setError("Não foi possível carregar as empresas");
      })
      .catch((error) => {
        setError(error.message);
      });
    if (state?.user?.id) {
      axios
        .get(LOGIN_URL + `/users/${state.user.id}`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        .then((response) => {
          isLoading(false);
          console.log(response);
          if (response.status === 200) {
            return setUserCompanies(response.data.companies);
          }
          setError("Não foi possível carregar as empresas");
        })
        .catch((error) => {
          setError(error.message);
        });
    }
  };

  useEffect(loadList, []);

  const handleChangeCompanies = (event) => {
    let currentUserCompanies = event.target.value.map((company) => {
      return {
        id: company.id,
        fantasyName: company.fantasyName,
        cnpj: company.cnpj,
        active: company.active,
      };
    });
    currentUserCompanies = currentUserCompanies.filter((company) => {
      return (
        currentUserCompanies.filter((currentCompany) => {
          return currentCompany.id === company.id;
        }).length === 1
      );
    });
    setUserCompanies(currentUserCompanies);
  };

  const handleChangePermissions = (event) => {
    setUserPermissions(event.target.value);
  };

  let action = ACTIONS.create;
  let initialValues = {};
  if (state && state.user) {
    action = ACTIONS.edit;
    initialValues = {
      name: state.user.name,
      email: state.user.email,
    };
  }

  return (
    <React.Fragment>
      <Helmet title="Usuários" />

      <Grid container spacing={6} justify="center">
        <Grid item lg={12} xl={9}>
          <Typography variant="h2" gutterBottom display="inline">
            {action.title}
          </Typography>

          <Divider my={6} />
          <Formik
            initialValues={{
              submit: false,
              ...initialValues,
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("O nome é obrigatório"),
              email: Yup.string()
                .email("O email deve ser válido")
                .max(255)
                .required("O email é obrigatório"),
              password: Yup.string().max(255),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                await action.action(
                  {
                    ...values,
                    companies: userCompanies,
                    permissions: userPermissions,
                  },
                  jwt,
                  initialValues.email
                );
                setStatus({ success: true });
              } catch (error) {
                const message = error.error || "Algo deu errado";

                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert mt={2} mb={1} severity="warning">
                    {errors.submit}
                  </Alert>
                )}
                {!errors.submit && status?.success && (
                  <Alert mt={2} mb={1} severity="success">
                    {action.success}
                  </Alert>
                )}
                <Grid container spacing={2}>
                  <Grid item xl={6} xs={12}>
                    <TextField
                      type="text"
                      name="name"
                      label="Nome"
                      value={values.name}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xl={6} xs={12}>
                    <TextField
                      type="email"
                      name="email"
                      label="Email"
                      value={values.email}
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <TextField
                      type="password"
                      name="password"
                      label={action.password}
                      value={values.password}
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Divider my={6} />

                    <Typography variant="h3" gutterBottom display="inline">
                      Empresas
                    </Typography>
                  </Grid>

                  <Grid item xl={12} xs={12}>
                    <InputLabel id="demo-mutiple-checkbox-label">
                      Selecione empresas que esse usuário tem acesso
                    </InputLabel>
                    {!!error && (
                      <Alert mt={2} mb={1} severity="warning">
                        {error}
                      </Alert>
                    )}
                    {loading && (
                      <Typography gutterBottom display="inline">
                        Carregando lista...
                      </Typography>
                    )}
                    {!loading && (
                      <Select
                        labelId="demo-mutiple-checkbox-label"
                        id="demo-mutiple-checkbox"
                        multiple
                        value={userCompanies}
                        onChange={handleChangeCompanies}
                        input={<Input />}
                        renderValue={(selected) =>
                          selected
                            .map(({ fantasyName }) => fantasyName)
                            .join(", ")
                        }
                        MenuProps={MenuProps}
                        fullWidth
                      >
                        {companies.map((company) => (
                          <MenuItem key={company.id} value={company}>
                            <Checkbox
                              checked={
                                userCompanies.filter(
                                  ({ id }) => id === company.id
                                ).length > 0
                              }
                            />
                            <ListItemText primary={company.fantasyName} />
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider my={6} />

                  <Typography variant="h3" gutterBottom display="inline">
                    Permissões
                  </Typography>
                </Grid>
                <Grid item xl={12} xs={12}>
                  <Select
                    labelId="permissions-label"
                    id="permissions"
                    multiple
                    value={userPermissions}
                    onChange={handleChangePermissions}
                    input={<Input />}
                    renderValue={(selected) =>
                      selected
                        .map(
                          (currentPermission) =>
                            PERMISSIONS.find(
                              (permission) =>
                                permission.id === currentPermission
                            ).value
                        )
                        .join(", ")
                    }
                    MenuProps={MenuProps}
                    fullWidth
                  >
                    {PERMISSIONS.map((permission) => (
                      <MenuItem key={permission.id} value={permission.id}>
                        <Checkbox
                          checked={userPermissions.includes(permission.id)}
                        />
                        <ListItemText primary={permission.value} />
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Divider my={6} />
                <CreateButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  {action.button}
                </CreateButton>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default withTheme(withRouter(UserForm));

import React from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import styled, { withTheme } from "styled-components/macro";
import {
  Button,
  Grid,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Divider as MuiDivider,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Switch,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { Alert } from "@material-ui/lab";
import axios from "../../../utils/axios";
import { LOGIN_URL } from "../../../constants/urls";

const TextField = styled(MuiTextField)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

const CreateButton = styled(Button)`
  padding: 10px 16px;
`;

const FacebookCopyGrid = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const createCompany = async (company, jwt) => {
  return new Promise((resolve, reject) => {
    axios
      .post(LOGIN_URL + "/companies/create", company, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error.message);
      });
  });
};

const editCompany = async (company, jwt) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        LOGIN_URL + "/companies/edit",
        {
          company,
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          return resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error) => {
        reject(error.message);
      });
  });
};

const ACTIONS = {
  create: {
    title: "Criar Nova Empresa",
    button: "Criar Empresa",
    action: createCompany,
    success: "Empresa criada com sucesso",
  },
  edit: {
    title: "Editar Empresa",
    button: "Editar Empresa",
    action: editCompany,
    success: "Empresa alterada com sucesso",
  },
};

function CompanyForm({ location: { state } }) {
  console.log(state);
  const {
    user: { jwt },
  } = useSelector((state) => state.authReducer);

  let action = ACTIONS.create;
  let initialValues = { active: true };
  if (state && state.company) {
    action = ACTIONS.edit;
    initialValues = {
      id: state.company.id,
      cnpj: state.company.cnpj,
      companyName: state.company.companyName,
      fantasyName: state.company.fantasyName,
      stateInscription: state.company.stateInscription,
      active: state.company.active,
      address: state.company.address,
      addressNumber: state.company.addressNumber,
      addressDistrict: state.company.addressDistrict,
      addressCity: state.company.addressCity,
      addressState: state.company.addressState,
      addressCep: state.company.addressCep,
      addressComplement: state.company.addressComplement,
      emails: state.company.emails,
      phones: state.company.phones,
      contractValue: state.company.contractValue,
      dueDay: state.company.dueDay ?? undefined,
      paymentType: state.company.paymentType,
    };
  }

  return (
    <React.Fragment>
      <Helmet title="Usuários" />

      <Grid container spacing={6} justify="center">
        <Grid item lg={12} xl={9}>
          <Typography variant="h2" gutterBottom display="inline">
            {action.title}
          </Typography>

          <Divider my={6} />
          <Formik
            initialValues={{
              submit: false,
              ...initialValues,
            }}
            validationSchema={Yup.object().shape({
              cnpj: Yup.string().required("O CNPJ é obrigatório"),
              companyName: Yup.string().required(
                "A Razão Social é obrigatória"
              ),
              fantasyName: Yup.string().required(
                "O Nome Fantasia é obrigatório"
              ),
              dueDay: Yup.number().min(1).max(28),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting, resetForm }
            ) => {
              try {
                await action.action(values, jwt);
                setStatus({ success: true });
              } catch (error) {
                const message = error.error || "Algo deu errado";

                setStatus({ success: false });
                setErrors({ submit: message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
            }) => (
              <form noValidate onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert mt={2} mb={1} severity="warning">
                    {errors.submit}
                  </Alert>
                )}
                {!errors.submit && status?.success && (
                  <Alert mt={2} mb={1} severity="success">
                    {action.success}
                  </Alert>
                )}
                <Grid container spacing={2}>
                  <Grid item xl={6} xs={12}>
                    <TextField
                      type="text"
                      name="cnpj"
                      label="CNPJ"
                      value={values.cnpj}
                      error={Boolean(touched.cnpj && errors.cnpj)}
                      fullWidth
                      helperText={touched.cnpj && errors.cnpj}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xl={6} xs={12}>
                    <TextField
                      type="text"
                      name="fantasyName"
                      label="Nome Fantasia"
                      value={values.fantasyName}
                      error={Boolean(touched.fantasyName && errors.fantasyName)}
                      fullWidth
                      helperText={touched.fantasyName && errors.fantasyName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <TextField
                      type="text"
                      name="companyName"
                      label="Razão Social"
                      value={values.companyName}
                      error={Boolean(touched.companyName && errors.companyName)}
                      fullWidth
                      helperText={touched.companyName && errors.companyName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <TextField
                      type="text"
                      name="stateInscription"
                      label="Inscrição Estadual"
                      value={values.stateInscription}
                      error={Boolean(
                        touched.stateInscription && errors.stateInscription
                      )}
                      fullWidth
                      helperText={
                        touched.stateInscription && errors.stateInscription
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <FormControlLabel
                      control={
                        <Switch
                          name="active"
                          checked={values.active}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      }
                      label="Ativo"
                    />
                  </Grid>

                  <Grid item xl={12} xs={12}>
                    <Divider my={6} />

                    <Typography variant="h3" gutterBottom display="inline">
                      Contato
                    </Typography>
                  </Grid>
                  <Grid item sm={8} xs={12}>
                    <TextField
                      type="text"
                      name="address"
                      label="Endereço"
                      value={values.address}
                      error={Boolean(touched.address && errors.address)}
                      fullWidth
                      helperText={touched.address && errors.address}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <TextField
                      type="text"
                      name="addressNumber"
                      label="Número"
                      value={values.addressNumber}
                      error={Boolean(
                        touched.addressNumber && errors.addressNumber
                      )}
                      fullWidth
                      helperText={touched.addressNumber && errors.addressNumber}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      type="text"
                      name="addressDistrict"
                      label="Bairro"
                      value={values.addressDistrict}
                      error={Boolean(
                        touched.addressDistrict && errors.addressDistrict
                      )}
                      fullWidth
                      helperText={
                        touched.addressDistrict && errors.addressDistrict
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      type="text"
                      name="addressCity"
                      label="Cidade"
                      value={values.addressCity}
                      error={Boolean(touched.addressCity && errors.addressCity)}
                      fullWidth
                      helperText={touched.addressCity && errors.addressCity}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      type="text"
                      name="addressState"
                      label="Estado"
                      value={values.addressState}
                      error={Boolean(
                        touched.addressState && errors.addressState
                      )}
                      fullWidth
                      helperText={touched.addressState && errors.addressState}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      type="text"
                      name="addressCep"
                      label="CEP"
                      value={values.addressCep}
                      error={Boolean(touched.addressCep && errors.addressCep)}
                      fullWidth
                      helperText={touched.addressCep && errors.addressCep}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <TextField
                      type="text"
                      name="addressComplement"
                      label="Complemento"
                      value={values.addressComplement}
                      error={Boolean(
                        touched.addressComplement && errors.addressComplement
                      )}
                      fullWidth
                      helperText={
                        touched.addressComplement && errors.addressComplement
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <TextField
                      type="text"
                      name="phones"
                      label="Telefones (separados por vírgula)"
                      value={values.phones}
                      error={Boolean(touched.phones && errors.phones)}
                      fullWidth
                      helperText={touched.phones && errors.phones}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <TextField
                      type="text"
                      name="emails"
                      label="Emails (separados por vírgula)"
                      value={values.emails}
                      error={Boolean(touched.emails && errors.emails)}
                      fullWidth
                      helperText={touched.emails && errors.emails}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>

                  <Grid item xl={12} xs={12}>
                    <Divider my={6} />

                    <Typography variant="h3" gutterBottom display="inline">
                      Financeiro
                    </Typography>
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <TextField
                      type="number"
                      name="contractValue"
                      label="Valor de contrato (sem divisão de milhar, decimal por ponto .)"
                      value={values.contractValue}
                      error={Boolean(
                        touched.contractValue && errors.contractValue
                      )}
                      fullWidth
                      helperText={touched.contractValue && errors.contractValue}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                    />
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <TextField
                      type="number"
                      name="dueDay"
                      label="Dia de vencimento (padrão: dia 20)"
                      value={values.dueDay}
                      error={Boolean(touched.dueDay && errors.dueDay)}
                      fullWidth
                      helperText={touched.dueDay && errors.dueDay}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                      InputProps={{ inputProps: { min: 1, max: 28 } }}
                    />
                  </Grid>
                  <Grid item xl={12} xs={12}>
                    <TextField
                      type="text"
                      name="paymentType"
                      label="Forma de pagamento"
                      value={values.paymentType}
                      error={Boolean(touched.paymentType && errors.paymentType)}
                      fullWidth
                      helperText={touched.paymentType && errors.paymentType}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      my={2}
                      select
                    >
                      <MenuItem value="boleto">Boleto</MenuItem>
                      <MenuItem value="transference">Transferência</MenuItem>
                    </TextField>
                  </Grid>
                  <Divider my={6} />
                </Grid>
                <CreateButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                >
                  {action.button}
                </CreateButton>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default withTheme(withRouter(CompanyForm));

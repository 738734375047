import React from "react";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import { Formik } from "formik";

import {
  Box,
  Chip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { CloudDownload } from "@material-ui/icons";

function intToCurrency(value) {
  if (!value) return null;
  return parseFloat(value).toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
}

function formatTax(tax) {
  if (!tax) return null;
  return tax > 100
    ? intToCurrency(tax)
    : parseFloat(tax).toLocaleString("pt-br") + "%";
}

function formatCNPJ(value) {
  return value
    ? value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
    : null;
}

const paymentstatus = {
  //waiting for payment
  PENDING: "Aguardando pagamento",
  AWAITING_RISK_ANALYSIS: "Pagamento em análise",
  //paid
  RECEIVED: "Fatura paga",
  CONFIRMED: "Confirmado",
  RECEIVED_IN_CASH: "Recebimento em dinheiro",
  //overdue
  OVERDUE: "Vencida",
  REFUNDED: "Estornada",
  REFUND_REQUESTED: "Estorno Solicitado",
  CHARGEBACK_REQUESTED: "Recebido chargeback",
  CHARGEBACK_DISPUTE: "Em disputa de chargeback",
  AWAITING_CHARGEBACK_REVERSAL: "Disputa vencida",
  DUNNING_REQUESTED: "Em processo de negativação",
  DUNNING_RECEIVED: "Recuperada",
};

const WAITING_FOR_PAYMENT = "WAITING_FOR_PAYMENT";
const PAID = "PAID";
const OVERDUE = "OVERDUE";

const paymentstatusType = {
  //waiting for payment
  PENDING: WAITING_FOR_PAYMENT,
  AWAITING_RISK_ANALYSIS: WAITING_FOR_PAYMENT,
  //paid
  RECEIVED: PAID,
  CONFIRMED: PAID,
  RECEIVED_IN_CASH: PAID,
  //overdue
  OVERDUE: OVERDUE,
  REFUNDED: OVERDUE,
  REFUND_REQUESTED: OVERDUE,
  CHARGEBACK_REQUESTED: OVERDUE,
  CHARGEBACK_DISPUTE: OVERDUE,
  AWAITING_CHARGEBACK_REVERSAL: OVERDUE,
  DUNNING_REQUESTED: OVERDUE,
  DUNNING_RECEIVED: OVERDUE,
};

const filterYears = [];
for (let year = 2022; year <= new Date().getFullYear(); year++) {
  filterYears.push(year);
}

const statusColor = {
  WAITING_FOR_PAYMENT: "#ff9800",
  PAID: "#4caf50",
  OVERDUE: "#f44336",
};

function translateStatus(status) {
  if (!status) {
    return;
  }
  return (
    <Chip
      label={paymentstatus[status]}
      style={{ backgroundColor: statusColor[paymentstatusType[status]] }}
    ></Chip>
  );
}

function createPDFLink(paymentUrl, invoiceUrl) {
  return paymentUrl || invoiceUrl ? (
    <IconButton
      mx={2}
      aria-label="downloads"
      onClick={() => {
        if (paymentUrl) window.open(paymentUrl, "_blank");
        if (invoiceUrl) window.open(invoiceUrl, "_blank");
      }}
    >
      <CloudDownload fontSize="small" />
    </IconButton>
  ) : null;
}

const Divider = styled(MuiDivider)(spacing);

const OverflowXGrid = styled(Grid)`
  overflow-x: scroll;
`;

function sortFinances(finances) {
  return finances.sort(function (a, b) {
    if (a.year < b.year) {
      return 1;
    }
    if (a.year > b.year) {
      return -1;
    }
    if (a.month < b.month) {
      return 1;
    }
    if (a.month > b.month) {
      return -1;
    }
    return 0;
  });
}

function FinancialGrid({ data: finances, fromClient = false }) {
  finances = sortFinances(finances);
  const companies = [
    ...new Set(
      finances.map((finance) => {
        return finance.company.fantasyName;
      })
    ),
  ].sort();
  const isRowFiltered = (finance, filters) => {
    if (filters.month && finance.month !== filters.month) {
      return false;
    }
    if (filters.year && finance.year !== filters.year) {
      return false;
    }
    if (filters.company && finance.fantasyName !== filters.company) {
      return false;
    }
    if (
      filters.paymentStatus &&
      finance.paymentStatus !== filters.paymentStatus
    ) {
      return false;
    }

    return true;
  };
  return (
    <React.Fragment>
      <Helmet title="Financeiro" />

      <OverflowXGrid container spacing={6}>
        <Grid item lg={12}>
          <Formik
            initialValues={{
              finances: finances,
            }}
          >
            {(formik) => (
              <form>
                <Divider my={6} />
                <TextField
                  type="text"
                  name="year"
                  label="Ano"
                  fullWidth
                  my={2}
                  select
                  {...formik.getFieldProps("year")}
                >
                  <MenuItem>Remove filtro</MenuItem>
                  {filterYears.map((year) => {
                    return <MenuItem value={year}>{year}</MenuItem>;
                  })}
                </TextField>
                <TextField
                  type="text"
                  name="month"
                  label="Mês"
                  fullWidth
                  my={2}
                  select
                  {...formik.getFieldProps("month")}
                >
                  <MenuItem>Remove filtro</MenuItem>
                  <MenuItem value="01">Janeiro</MenuItem>
                  <MenuItem value="02">Fevereiro</MenuItem>
                  <MenuItem value="03">Março</MenuItem>
                  <MenuItem value="04">Abril</MenuItem>
                  <MenuItem value="05">Maio</MenuItem>
                  <MenuItem value="06">Junho</MenuItem>
                  <MenuItem value="07">Julho</MenuItem>
                  <MenuItem value="08">Agosto</MenuItem>
                  <MenuItem value="09">Setembro</MenuItem>
                  <MenuItem value="10">Outubro</MenuItem>
                  <MenuItem value="11">Novembro</MenuItem>
                  <MenuItem value="12">Dezembro</MenuItem>
                </TextField>
                <TextField
                  type="text"
                  name="paymentStatus"
                  label="Status de pagamento"
                  fullWidth
                  my={2}
                  select
                  {...formik.getFieldProps("paymentStatus")}
                >
                  <MenuItem>Remove filtro</MenuItem>
                  {Object.keys(paymentstatus).map((key) => {
                    return (
                      <MenuItem value={key}>{paymentstatus[key]}</MenuItem>
                    );
                  })}
                </TextField>
                {fromClient ? (
                  <></>
                ) : (
                  <TextField
                    type="text"
                    name="company"
                    label="Loja"
                    fullWidth
                    my={2}
                    select
                    {...formik.getFieldProps("company")}
                  >
                    <MenuItem>Remove filtro</MenuItem>
                    {companies.map((company) => {
                      return <MenuItem value={company}>{company}</MenuItem>;
                    })}
                  </TextField>
                )}

                <Divider my={6} />

                <Box mt={3}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {fromClient ? (
                          <></>
                        ) : (
                          <>
                            <TableCell>Loja</TableCell>
                            <TableCell>CNPJ</TableCell>
                          </>
                        )}
                        <TableCell>Período</TableCell>
                        <TableCell>Descrição</TableCell>
                        <TableCell align="right">Total</TableCell>
                        <TableCell align="left">Status</TableCell>
                        <TableCell align="right">Arquivos</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {formik.values.finances.map((finance) => {
                        if (isRowFiltered(finance, formik.values)) {
                          return (
                            <TableRow key={finance.id}>
                              {fromClient ? (
                                <></>
                              ) : (
                                <>
                                  <TableCell>{finance.fantasyName}</TableCell>
                                  <TableCell>
                                    {formatCNPJ(finance.cnpj)}
                                  </TableCell>
                                </>
                              )}

                              <TableCell component="th">
                                {finance.month}/{finance.year}
                              </TableCell>
                              <TableCell>{finance.description}</TableCell>
                              <TableCell align="right">
                                {intToCurrency(finance.value) ?? "R$ 0,00"}
                              </TableCell>
                              <TableCell align="left">
                                {translateStatus(finance?.paymentStatus)}
                              </TableCell>
                              <TableCell align="right">
                                {createPDFLink(
                                  paymentstatusType[finance?.paymentStatus] ===
                                    PAID
                                    ? null
                                    : finance?.paymentBankSlipUrl,
                                  finance?.invoiceStatus === "AUTHORIZED"
                                    ? finance?.invoicePdfUrl
                                    : null
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </form>
            )}
          </Formik>
        </Grid>
      </OverflowXGrid>
    </React.Fragment>
  );
}

export default FinancialGrid;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";

import { Grid, Typography as MuiTypography } from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { Alert } from "@material-ui/lab";
import FinancialGrid from "../common/FinancialGrid";
import axios from "../../utils/axios";
import { LOGIN_URL } from "../../constants/urls";

const Typography = styled(MuiTypography)(spacing);

function Default() {
  const { user } = useSelector((state) => state.authReducer);
  const [financialData, setFinancialData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const loadAndSave = async () => {
    setLoading(true);
    const { jwt, selectedCompany } = user;
    const companyId = selectedCompany?.id;

    const response = await axios.get(
      `${LOGIN_URL}/companies/${companyId}/finances`,
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      }
    );

    if (response.status === 200) {
      setFinancialData(response.data);
    } else {
      setError("Não foi possível carregar os dados");
    }
    setLoading(false);
  };

  useEffect(() => {
    loadAndSave();
  }, [user]);

  const companyMessage = user.selectedCompany
    ? `Aqui estão os dados financeiros da loja ${user.selectedCompany.fantasyName}`
    : "Nenhuma empresa está vinculada a esse usuário";

  return (
    <React.Fragment>
      <Grid justify="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Financeiro
          </Typography>
          <Typography variant="subtitle1">
            Olá, {user.name}! {companyMessage}.{" "}
            <span role="img" aria-label="Waving Hand Sign">
              👋
            </span>
          </Typography>
        </Grid>
      </Grid>

      {loading && (
        <Alert mt={2} mb={1} severity="info">
          Carregando...
        </Alert>
      )}

      {!!error && (
        <Alert mt={2} mb={1} severity="warning">
          {error}
        </Alert>
      )}

      {!!user.selectedCompany && !error && !loading && (
        <FinancialGrid data={financialData} fromClient={true} />
      )}
    </React.Fragment>
  );
}

export default Default;
